import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { firestoreConnect } from 'react-redux-firebase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase';
import Notes from './Notes';
import Tags from './Tags';
import Review from './Review';
import Rating from './Rating';
import * as Helper from '../util/Helper.js';
import { withStyles } from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Modal from '../modal';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  tabs: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    minHeight: '2rem',
    borderBottom: '1px solid #e0e2e4',
  },
  tab: {
    fontSize: '0.875rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
    minHeight: 24,
    marginBottom: 0,
    marginRight: 0,
    fontWeight: 700,
  },
  tabLabel: {
    padding: '0.25rem 1.125rem',
    textTransform: 'capitalize',
    color: theme.modeColors.textColor,
  },
  selected: {
    color: `${theme.modeColors.selectedColor} !important`,
  },
});

class ElementTabs extends Component {
  state = {
    tab: 0,
    summary: false,
    description: false,
  };

  onChangeTab = (e, v) => {
    this.setState({ tab: v });
  };

  gitStatistic = () => {
    const { proposal } = this.props;
    if (proposal && proposal.excelData) {
      const { statistic } = proposal.excelData;

      return statistic
        ? _.map(statistic, (value, key) => {
            const statisticKey = Object.keys(value)[0];
            const statisticValue = value[Object.keys(value)[0]];
            // Add space between thousands
            const formattedValue = this.filterSize(statisticValue)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            return statisticValue ? (
              <div key={key} className="xs d-flex align-items-end pb-1">
                <span className="col mr-3">{statisticKey}:</span>
                <span className="col-1 text-right">{formattedValue}</span>
                {/* <span className="col-1 text-right">
                  {this.filterSize(formattedValue)}
                </span> */}
              </div>
            ) : null;
          })
        : null;
    }
    return null;
  };

  filterSize = v => {
    let number;
    if (v.includes(' m2')) {
      number = v.replace(' m2', '');
    } else if (v.includes(' gfm2')) {
      number = v.replace(' gfm2', '');
    } else if (v.includes(' spaces')) {
      number = v.replace(' spaces', '');
    } else if (v.includes(' persons')) {
      number = v.replace(' persons', '');
    } else if (v.includes(' jobs')) {
      number = v.replace(' jobs', '');
    } else {
      number = v;
    }

    return Number.isInteger(parseFloat(number)) ? number : Number(number).toFixed(2);
  };

  openSumarry = () => this.setState({ summary: true });
  closeSumarry = () => this.setState({ summary: false });

  openDescription = () => this.setState({ description: true });
  closeDescription = () => this.setState({ description: false });
  createMarkup(data) {
    const dataArr = data.split('\n');

    return dataArr.map((data, index) => `<p key=des-${index}>${data}</p>`).join('');
  }

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const { tab, summary, description } = this.state;
    const {
      classes,
      proposal,
      proposalId,
      PublicTags,
      profile,
      proposalsData,
      users,
    } = this.props;
    const proposalPublicNote = Helper.getProposalPublicNote(proposalsData, proposalId);
    const proposalPrivateNote = Helper.getProposalPrivateNote(profile, proposalId);
    const proposalPublicReview = Helper.getProposalPublicReview(
      proposalsData,
      proposalId,
      'en'
    );
    const proposalPublicReviewFI = Helper.getProposalPublicReview(
      proposalsData,
      proposalId,
      'fi'
    );
    const proposalPrivateRivew = Helper.getProposalPrivateReview(profile, proposalId);
    const proposalPublicTags = Helper.getProposalPublicTags(proposalId, proposalsData);
    const proposalPrivateTags = Helper.getProposalPrivateTags(profile, proposalId);
    const privateTags = _.get(profile, 'PrivateTags', []);
    const Statistic = this.gitStatistic();
    const proposalPrivateRate = Helper.getProposalPrivateRate(profile, proposalId);
    const proposalPublicRate = Helper.getProposalPublicRate(proposalsData, proposalId);
    const proposalAllRate = !readOnly && Helper.getProposalAllRate(users, proposalId);
    const infoTabIndex = readOnly ? tab === 0 : tab === 2;
    return (
      <>
        <Tabs
          value={tab}
          classes={{ root: classes.tabs }}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onChangeTab}
        >
          {!readOnly && (
            <>
              <Tab
                label="Private"
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
              <Tab
                label="Public"
                classes={{
                  root: classes.tab,
                  selected: classes.selected,
                  wrapper: classes.tabLabel,
                }}
              />
            </>
          )}

          <Tab
            label="Info"
            classes={{
              root: classes.tab,
              selected: classes.selected,
              wrapper: classes.tabLabel,
            }}
          />
        </Tabs>

        {tab === 0 && !readOnly && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPrivateNote}
                edit={true}
                title="My quick notes"
                onSave={note => Helper.updatePrivateNote(note, proposalId, this.props)}
              />
            </div>

            <div className="py-2 border-bottom">
              <Review
                edit={true}
                title={profile.role !== 'judge' ? 'Jury review setup' : 'My review'}
                proposal={proposal}
                onSave={review =>
                  Helper.updatePrivateReview(review, proposalId, this.props)
                }
                review={proposalPrivateRivew}
              />
            </div>

            <div className="py-2 border-bottom">
              <Tags
                title={profile.role !== 'judge' ? 'Jury tags setup' : 'My tags'}
                edit={true}
                tags={privateTags}
                proposalTags={proposalPrivateTags}
                onCreate={tag =>
                  Helper.createPrivateTag(
                    tag,
                    privateTags,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTag={tagId =>
                  Helper.removePrivateTag(tagId, privateTags, this.props)
                }
                addTagToProposal={tag =>
                  Helper.addPrivateTagToProposal(
                    tag,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                removeTagFromProposal={tagId =>
                  Helper.removePrivateTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPrivateTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            <div className="pt-2 pb-1 border-bottom">
              <Rating
                title={profile.role !== 'judge' ? 'Jury rating setup' : 'My rating'}
                edit="true"
                proposal={proposal}
                onSave={
                  profile.role !== 'judge'
                    ? rate => Helper.updatePublicRate(rate, proposalId, this.props)
                    : rate => Helper.updatePrivateRate(rate, proposalId, this.props)
                }
                rate={profile.role !== 'judge' ? proposalPublicRate : proposalPrivateRate}
                allRates={proposalAllRate}
                secretary={profile.role !== 'judge'}
              />
            </div>
          </div>
        )}

        {tab === 1 && !readOnly && (
          <div>
            <div className="py-2 border-bottom">
              <Notes
                text={proposalPublicNote}
                title="Secretary notes"
                edit={profile.role !== 'judge'}
                onSave={note => Helper.updatePublicNote(note, proposalId, this.props)}
              />
            </div>

            <div className="py-2 border-bottom">
              <Review
                edit={profile.role !== 'judge'}
                title="Jury review"
                proposal={proposal}
                onSave={review =>
                  Helper.updatePublicReview(
                    review,
                    proposalId,
                    this.props,
                    this.props.i18n.language
                  )
                }
                review={proposalPublicReview}
                reviewFI={proposalPublicReviewFI}
                enableLang
              />
            </div>

            <div className="py-2 border-bottom">
              <Tags
                title="Jury tags"
                edit={profile.role !== 'judge'}
                onCreate={tag =>
                  Helper.createPublicTag(tag, this.props, proposalId, proposalPublicTags)
                }
                tags={PublicTags ? PublicTags : []}
                addTagToProposal={tag =>
                  Helper.addPublicTagToProposal(
                    tag,
                    proposalId,
                    this.props,
                    proposalPublicTags
                  )
                }
                proposalTags={proposalPublicTags}
                removeTag={tagId => Helper.removePublicTag(tagId, this.props)}
                removeTagFromProposal={tagId =>
                  Helper.removePublicTagFromProposal(
                    tagId,
                    proposalId,
                    proposalPublicTags,
                    this.props
                  )
                }
                proposal={proposal}
              />
            </div>
            <div className="pt-2 pb-1 border-bottom">
              <Rating title="Jury rating" proposal={proposal} rate={proposalPublicRate} />
            </div>
          </div>
        )}

        {/* There's infoTabIndex && here ? */}
        {infoTabIndex && proposal.excelData && (
          <div>
            <h5>Key figures of the entry</h5>
            {Statistic}
            <div>
              {proposal.excelData &&
                proposal.excelData.description &&
                proposal.excelData.description[1] && (
                  <Button
                    style={{ marginTop: '2rem' }}
                    size="md"
                    variant="contained"
                    color="primary"
                    onClick={this.openSumarry}
                  >
                    Description summary
                  </Button>
                )}
              {proposal.excelData &&
                proposal.excelData.description &&
                proposal.excelData.description[2] && (
                  <Button
                    style={{ marginTop: '1rem' }}
                    size="md"
                    variant="contained"
                    color="primary"
                    onClick={this.openDescription}
                  >
                    Description
                  </Button>
                )}
            </div>
          </div>
        )}

        {/* the Summary description and Description fetch from the excelData.description by index it should change on the future */}
        {proposal.excelData &&
          proposal.excelData.description &&
          proposal.excelData.description[1] && (
            <Modal
              close={this.closeSumarry}
              isOpen={summary}
              title="Summary description"
              proposal={proposal}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.createMarkup(
                    Object.values(proposal.excelData.description[1])[0]
                  ),
                }}
              />
            </Modal>
          )}
        {proposal.excelData &&
          proposal.excelData.description &&
          proposal.excelData.description[2] && (
            <Modal
              close={this.closeDescription}
              isOpen={description}
              title="Description"
              proposal={proposal}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.createMarkup(
                    Object.values(proposal.excelData.description[2])[0]
                  ),
                }}
              />
            </Modal>
          )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    profile: state.firebase.profile,
    PublicTags: state.firestore.ordered.PublicTags,
    users: state.firestore.ordered.users,
    proposalsData: state.firestore.ordered.proposals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'users' },
    { collection: 'proposals' },
    { collection: 'PublicTags', orderBy: ['position', 'asc'] },
  ])
)(withSnackbar(withStyles(styles)(withTranslation()(ElementTabs))));
