import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../login/Login';
import Navbar from '../navbar/Navbar';
import Proposals from '../proposals';
import CompetitionInfo from '../competition-info';
import Profile from '../profile/Profile';
import Settings from '../settings';
import SingleProposal from '../single-proposal';
import Comparing from '../comparing';
import PublicComponent from './PublicComponent';
import PrivateComponent from './PrivateComponent';
import { styled } from '@material-ui/styles';

const AppBackGroundColor = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.modeColors.rootBackGround,
}));

class App extends Component {
  state = {
    activeSettingsTab: 0,
  };

  setActiveSettingsTab = activeSettingsTab => {
    this.setState({ activeSettingsTab });
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const { themeMode, toggleState } = this.props;

    if (readOnly) {
      return (
        <AppBackGroundColor>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Proposals} />
            <Route exact path="/competition-info" component={CompetitionInfo} />
            <Route exact path="/proposal/:id" component={SingleProposal} />
            <Redirect from="*" to="/" />
          </Switch>
        </AppBackGroundColor>
      );
    } else {
      return (
        <AppBackGroundColor>
          <Navbar />
          <Switch>
            <Route
              exact
              path="/login"
              render={() => <PublicComponent component={Login} />}
            />
            <Route
              exact
              path="/"
              render={() => <PrivateComponent component={Proposals} />}
            />
            <Route
              exact
              path="/competition-info"
              render={() => <PrivateComponent component={CompetitionInfo} />}
            />
            <Route
              exact
              path="/profile"
              render={() => <PrivateComponent component={Profile} />}
            />
            <Route
              exact
              path="/settings"
              render={() => (
                <PrivateComponent
                  component={() => (
                    <Settings
                      themeMode={themeMode}
                      clicked={toggleState}
                      activeSettingsTab={this.state.activeSettingsTab}
                      setActiveSettingsTab={this.setActiveSettingsTab}
                    />
                  )}
                />
              )}
            />
            <Route
              exact
              path="/proposal/:id"
              render={() => <PrivateComponent component={SingleProposal} />}
            />
            <Route
              exact
              path="/comparing"
              render={() => <PrivateComponent component={Comparing} />}
            />
          </Switch>
        </AppBackGroundColor>
      );
    }
  }
}

export default App;
