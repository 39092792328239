import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import OpenSeaDragon from 'openseadragon';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Button from '../inputs/Button';
import AdapterLink from '../util/AdapterLink';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 'calc(100% - 264px)',
    position: 'fixed',
    top: 40,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#999da0',
    zIndex: 1000,
  },
  dialogPaper2: {
    borderRadius: 0,
    width: 'calc(100% - 36px)',
    position: 'fixed',
    top: 40,
    right: 264,
    bottom: 0,
    left: 0,
    backgroundColor: '#999da0',
    zIndex: 1000,
  },
  container: {
    height: '100%',
    padding: '0 24',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    width: '100%',
    margin: '0',
    background: theme.modeColors.elementImageContainer,
    flex: 1,
  },
  thumnails_carousel: {
    padding: '12px 40px 12px',
  },
  thumbnail: {
    maxHeight: '6rem',
    widht: 'auto',
    margin: '0 0.5rem',
    cursor: 'pointer',
    maxWidth: '7rem',
  },
  thumbnailActive: {
    maxHeight: '6rem',
    widht: 'auto',
    margin: '0 0.5rem',
    cursor: 'pointer',
    maxWidth: '7rem',
    border: '3px solid #3f51b5',
  },
  zoombuttons: {
    position: 'absolute',
    zIndex: 100,
    left: 24,
    top: 16,

    '& button': {
      paddingBottom: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 100,
    right: 24,
    top: 70,
    padding: 8,
  },
  carousel: {
    boxShadow: ' 0 -1px 3px 0 rgba(0, 0, 0, 0.2)',
    background: theme.modeColors.carouselBg,
  },
});

let viewer;
const viewerDefOptns = {
  zoomInButton: 'zoom-in',
  zoomOutButton: 'zoom-out',
  animationTime: 0.5,
  showNavigationControl: false,
};

class ManageTags extends React.Component {
  state = {
    id: '',
    link: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { reactAppUrl } = this.props;

    if (this.props.id !== nextProps.id && nextProps.isOpen) {
      document.body.style.overflow = 'hidden';

      const prefixUrl = reactAppUrl + nextProps.element.prefix_url.replace(/ /g, '%20');
      const tileSources =
        reactAppUrl + nextProps.element.tileSource_url.replace(/ /g, '%20');

      this.setState(
        {
          id: nextProps.id,
          link: nextProps.link,
        },
        () => {
          if (viewer) {
            viewer.destroy();
          }

          viewer = new OpenSeaDragon.Viewer({
            ...viewerDefOptns,
            id: nextProps.id,
            prefixUrl: prefixUrl,
            tileSources: tileSources,
          });
        }
      );
    }
  }

  closeModal = () => {
    this.props.close();
    document.body.style.overflow = 'auto';
    viewer.destroy();
  };

  elemntsThumbnails = () => {
    const { reactAppUrl } = this.props;

    if (this.props.elements && !process.env.REACT_APP_READ_ONLY) {
      return Object.keys(this.props.elements).map((key, index) => {
        const value = this.props.elements[key];

        return (
          <div key={key} onClick={() => this.updateTheViewer(value, key, index)}>
            <img
              className={
                key === this.state.id
                  ? this.props.classes.thumbnailActive
                  : this.props.classes.thumbnail
              }
              src={reactAppUrl + value.thumbnail_url}
              alt={value.name}
            />
          </div>
        );
      });
    }

    // READ ONLY MODE
    const filteredElements =
      this.props.elements &&
      Object.entries(this.props.elements).filter(
        ([key, value]) => value.type === 'panel'
      );

    if (filteredElements) {
      return filteredElements.map((element, index) => {
        const key = element[0];
        const elementValue = this.props.elements[key];

        return (
          <div key={key} onClick={() => this.updateTheViewer(elementValue, key, index)}>
            <img
              className={
                key === this.state.id
                  ? this.props.classes.thumbnailActive
                  : this.props.classes.thumbnail
              }
              src={process.env.REACT_APP_URL + elementValue.thumbnail_url}
              alt={elementValue.name}
            />
          </div>
        );
      });
    }

    return null;
  };

  updateTheViewer = (val, key, index) => {
    const { reactAppUrl, match, openSingleElement } = this.props;

    if (!this.props.comparing) {
      if (match.params.id && key !== this.state.id) {
        // In single proposal view
        this.props.history.push({
          // of no proposal id found in match, search for proposal key as params
          pathname: `/proposal/${match.params.id}`,
          search: `view_mode=true&start_key=${key}&start_index=${index}`,
        });
      } else if (!match.params.id) {
        // In search result elements view (as modal)
        openSingleElement(val, key, val.proposal);
      }
    } else {
      if (key !== this.state.id) {
        viewer.destroy();
        const prefixUrl = reactAppUrl + val.prefix_url.replace(/ /g, '%20');
        const tileSources = reactAppUrl + val.tileSource_url.replace(/ /g, '%20');
        this.setState({
          id: key,
        });
        setTimeout(() => {
          viewer = new OpenSeaDragon.Viewer({
            id: key,
            prefixUrl: prefixUrl,
            tileSources: tileSources,
            zoomInButton: 'zoom-in',
            zoomOutButton: 'zoom-out',
          });
        }, 100);
      }
    }
  };

  render() {
    const { isOpen, classes, link, expand, noSlider } = this.props;
    const elemntsThumbnails = this.elemntsThumbnails();
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 3,
    };
    return (
      <div
        className={expand ? classes.dialogPaper2 : classes.dialogPaper}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={classes.container}>
          <div className="position-relative">
            <Fab
              variant="extended"
              className={classes.closeButton}
              onClick={this.closeModal}
            >
              <CloseIcon variant="contained" />
            </Fab>
          </div>
          <div className={classes.zoombuttons}>
            <Button
              onClick={() => console.log('zoom out')}
              variant="contained"
              style={{ backgroundColor: '#fff', border: '1px solid #ccced0' }}
              size="xs"
              id="zoom-out"
            >
              <ZoomOutIcon />
            </Button>
            <Button
              onClick={() => console.log('zoom in')}
              variant="contained"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #ccced0',
                borderLeft: 0,
              }}
              size="xs"
              id="zoom-in"
            >
              <ZoomInIcon />
            </Button>
          </div>
          <div className={classes.imageContainer} id={this.state.id}>
            {' '}
          </div>

          <div className={classes.carousel}>
            <div className="d-flex align-items-center justify-content-around py-1 ">
              {this.props.element && (
                <h3 className="mb-0 text-white">
                  Proposal: {this.props.proposalName}, {this.props.element.name}
                </h3>
              )}
              {link && (
                <Button
                  component={AdapterLink}
                  to={'/proposal/' + link}
                  variant="contained"
                  color="primary"
                  size="md"
                >
                  Go to the proposal of this element
                </Button>
              )}
            </div>

            {!noSlider && (
              <div className={classes.thumnails_carousel}>
                <Slider {...settings}> {elemntsThumbnails} </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ManageTags)));
