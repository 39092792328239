import React from 'react';
import { Page, View, Text, StyleSheet, Image, Font, Canvas } from '@react-pdf/renderer';
import moment from 'moment';
import RalewayNormal from '../assets/fonts/regular.ttf';
import RalewayBold from '../assets/fonts/bold.ttf';
import Overall from '../assets/icons/overall.png';
import Building from '../assets/icons/building.png';
import Deck from '../assets/icons/deck.png';
import Money from '../assets/icons/money.png';
import House from '../assets/icons/house.png';
import Transport from '../assets/icons/transport.png';
import Nature from '../assets/icons/nature.png';

import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  mainView: {
    padding: '18px 42px',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  head_small: {
    fontSize: 10,
    marginBottom: 8,
    color: '#353c43',
    fontWeight: 900,
    fontFamily: 'Raleway',
  },
  head_medium: {
    fontSize: 18,
    marginBottom: 16,
    color: '#353c43',
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  head_big: {
    fontSize: 40,
    color: '#353c43',
    fontWeight: 700,
  },
  pageDate: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 40,
    right: 0,
    color: 'grey',
  },
});

Font.register({
  family: 'Raleway',
  fonts: [
    { src: RalewayNormal }, // font-style: normal, font-weight: normal
    { src: RalewayBold, fontStyle: 'normal', fontWeight: 700 },
  ],
});

const PdfFirstPage = ({
  proposalClass,
  statistic,
  publicReviewArray,
  pageHeader,
  proposalData,
  curvePath,
  proposalPublicRate,
  hasProfile,
  reactAppUrl,
}) => {
  const { t } = useTranslation();

  const paint = (Painter, availableWidth, availableHeight) => {
    Painter.path(curvePath);
    Painter.lineWidth(3);
    Painter.strokeColor('#3f51b5');
    Painter.stroke();
  };

  const getHight = () => {
    if (proposalPublicRate.overall) {
      return (proposalPublicRate.overall / 5) * 100;
    }
    return 0;
  };

  const copyPublicReviewArray = [...publicReviewArray];
  const halfwayThrough =
    copyPublicReviewArray.length > 16
      ? Math.floor(copyPublicReviewArray.length / 2)
      : Math.ceil(copyPublicReviewArray.length / 2);
  const firstHalf = copyPublicReviewArray.slice(0, halfwayThrough);
  const secondHalf = copyPublicReviewArray.slice(
    halfwayThrough,
    copyPublicReviewArray.length
  );

  return (
    <Page size="A4" orientation="landscape">
      <View style={styles.mainView}>
        {pageHeader}
        <View
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '24.5%',
              borderTopColor: '#e0e2e4',
              borderTopWidth: 1,
              marginTop: 2,
            }}
          >
            <View
              style={{
                width: '100%',
                borderBottomColor: '#e0e2e4',
                borderBottomWidth: 1,
              }}
            >
              <Image
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  width: '100%',
                  maxHeight: '100%',
                }}
                src={reactAppUrl + proposalData.thumbnail_url}
              />
            </View>

            {/* Without profile, key figures is placed under thumbnail image */}
            {!hasProfile && (
              <>
                <Text style={{ ...styles.head_small, marginTop: 8, marginBottom: 8 }}>
                  {t('Key figures of the entry')}
                </Text>
                <View
                  style={{
                    borderBottomColor: '#e0e2e4',
                    borderBottomWidth: 1,
                    width: '100%',
                    paddingBottom: 8,
                  }}
                >
                  {statistic}
                </View>
              </>
            )}

            {hasProfile && (
              <Text style={{ ...styles.head_small, marginTop: 10, marginBottom: 0 }}>
                {t('Jury rating profile')}
              </Text>
            )}

            {hasProfile && (
              <View
                style={{
                  width: '100%',
                  borderBottomColor: '#e0e2e4',
                  borderBottomWidth: 1,
                  position: 'relative',
                  height: 130,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 5,
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    width: 12,
                    height: 120,
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Text
                    style={{ width: 6, height: 100, backgroundColor: '#e0e2e4' }}
                  ></Text>
                  <Text
                    style={{
                      width: 6,
                      height: getHight(),
                      backgroundColor: '#3f51b5',
                      position: 'absolute',
                      bottom: 20,
                    }}
                  ></Text>
                  <Image style={{ width: 10, height: 10, marginTop: 8 }} src={Overall} />
                </View>
                <View
                  style={{
                    display: 'flex',
                    width: 150,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={Transport}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={Building}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={Nature}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={Deck}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={House}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      width: 10,
                      height: 120,
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{ width: 1, height: 100, backgroundColor: '#e0e2e4' }}
                    ></Text>
                    <Image
                      style={{ width: 'auto', height: 10, marginTop: 8 }}
                      src={Money}
                    />
                  </View>
                </View>
                <Canvas
                  style={{
                    width: 150,
                    height: 100,
                    position: 'absolute',
                    top: 10,
                    right: 5,
                  }}
                  paint={paint}
                />
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 12,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10, height: 10 }} src={Overall} />
                </View>
                <Text style={{ fontSize: 8, fontWeight: 700, fontFamily: 'Raleway' }}>
                  {t('Overall rating.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={Transport} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {t('Traffic and transport.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={Building} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {t('Construction and architecture.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={Nature} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {t('Urban outdoor spaces and urban green areas.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={Deck} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {' '}
                  {t('Urban culture, events, and tourism.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={House} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {t('Housing and lifestyle.')}
                </Text>
              </View>
            )}

            {hasProfile && (
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 5,
                }}
              >
                <View style={{ width: 20, height: 12, marginRight: 10 }}>
                  <Image style={{ width: 10 }} src={Money} />
                </View>
                <Text style={{ fontSize: 8, fontFamily: 'Raleway' }}>
                  {t('The business and industrial sector and know-how.')}
                </Text>
              </View>
            )}
          </View>

          <View
            style={{
              width: '72%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {hasProfile && (
              <View
                style={{
                  width: '40%',
                  borderTopColor: '#e0e2e4',
                  borderTopWidth: 1,
                  marginTop: 2,
                }}
              >
                {/* Key figures */}
                <Text style={{ ...styles.head_small, marginTop: 8, marginBottom: 8 }}>
                  {t('Key figures of the entry')}
                </Text>
                <View
                  style={{
                    borderBottomColor: '#e0e2e4',
                    borderBottomWidth: 1,
                    width: '100%',
                    paddingBottom: 8,
                  }}
                >
                  {statistic}
                </View>
              </View>
            )}

            {/* Jury review */}
            <View
              style={{
                width: `${!hasProfile ? '100%' : '57%'}`,
                borderTopColor: '#e0e2e4',
                borderTopWidth: 1,
                marginTop: 2,
                marginLeft: `${!hasProfile ? '0' : '20px'}`,
              }}
            >
              <View
                style={{
                  // borderBottomColor: '#e0e2e4',
                  // borderBottomWidth: 1,
                  padding: '8px 0',
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ ...styles.head_small, paddingRight: 16, marginBottom: 0 }}>
                  {t('Jury review')}
                </Text>
                {proposalClass && (
                  <Text
                    style={{
                      padding: '2px 8px',
                      fontSize: 10,
                      color: '#fff',
                      backgroundColor: proposalClass.colour,
                    }}
                  >
                    {t(proposalClass.class)}
                  </Text>
                )}
              </View>

              {hasProfile && (
                <View
                  style={{
                    maxHeight: 200,
                  }}
                >
                  {publicReviewArray.map((item, i) => {
                    return (
                      <Text
                        style={{
                          fontSize: 8,
                          fontFamily: 'Raleway',
                          marginBottom: 4,
                        }}
                        key={i}
                      >
                        {item}
                      </Text>
                    );
                  })}
                </View>
              )}

              {!hasProfile && (
                <View
                  style={{
                    maxHeight: 200,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginBottom: 4,
                  }}
                >
                  <View style={{ width: '49%' }}>
                    {firstHalf.map((item, i) => {
                      return (
                        <Text
                          style={{
                            fontSize: 8,
                            fontFamily: 'Raleway',
                            marginBottom: 4,
                          }}
                          key={i}
                        >
                          {item}
                        </Text>
                      );
                    })}
                  </View>

                  <View style={{ width: '48%' }}>
                    {secondHalf.map((item, i) => {
                      return (
                        <Text
                          style={{
                            fontSize: 8,
                            fontFamily: 'Raleway',
                            marginBottom: 4,
                          }}
                          key={i}
                        >
                          {item}
                        </Text>
                      );
                    })}
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
      <Text
        style={styles.pageDate}
        render={() => `${moment().format('D.M.YYYY')}`}
        fixed
      />
    </Page>
  );
};

export default PdfFirstPage;
