import React from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';
import FlagFI from '../assets/icons/lang-fi.png';
import FlagEN from '../assets/icons/lang-en.png';

import Button from '../inputs/Button';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
  },
});

const ManageReview = ({
  title,
  close,
  isOpen,
  classes,
  proposal,
  edit,
  review,
  reviewFI,
  onSave,
  enableLang,
}) => {
  const { i18n } = useTranslation();
  // const [tag, setTag] = React.useState('');
  const [editor, setEditor] = React.useState(EditorState.createEmpty());

  React.useEffect(() => {
    let editorDetails;
    if (!enableLang || i18n.language === 'en') {
      if (review) {
        const contentBlock = htmlToDraft(review);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorDetails = EditorState.createWithContent(contentState);
      } else {
        editorDetails = EditorState.createEmpty();
      }
    } else if (enableLang && i18n.language === 'fi') {
      if (reviewFI) {
        const contentBlock = htmlToDraft(reviewFI);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorDetails = EditorState.createWithContent(contentState);
      } else {
        editorDetails = EditorState.createEmpty();
      }
    }
    setEditor(editorDetails);
  }, [review, reviewFI, enableLang, i18n.language]);

  const onEditorChange = editorState => {
    setEditor(editorState);
  };

  const createMarkup = data => {
    return { __html: data };
  };

  const onSaveHandler = () => {
    const htmlDetails = draftToHtml(convertToRaw(editor.getCurrentContent()));

    onSave(htmlDetails);
  };

  const saveAndClose = () => {
    onSaveHandler();
    close();
  };

  const changeLanguageHandler = language => {
    onSaveHandler();
    i18n.changeLanguage(language);
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="lg"
      fullWidth={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className="p-3">
        <div>
          <h2>
            {title}
            {enableLang && (
              <div className="d-inline-block ml-3">
                <button
                  type="button"
                  className={`lang-btn ${i18n.language === 'en' ? 'active' : ''}`}
                  style={{ marginRight: '5px' }}
                  onClick={() => changeLanguageHandler('en')}
                >
                  <img className="lang-img" src={FlagEN} alt="English" />
                </button>
                <button
                  type="button"
                  className={`lang-btn ${i18n.language === 'fi' ? 'active' : ''}`}
                  onClick={() => changeLanguageHandler('fi')}
                >
                  <img className="lang-img" src={FlagFI} alt="Suomi" />
                </button>
              </div>
            )}
          </h2>
          <h3>Proposal {proposal.number}</h3>
        </div>

        <div className="review-container mb-1">
          {edit && (
            <Editor
              editorState={editor}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class editor_container"
              toolbar={{
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
              }}
              onEditorStateChange={onEditorChange}
            />
          )}

          {!edit && <div dangerouslySetInnerHTML={createMarkup(review)} />}
        </div>

        {edit && (
          <div>
            <div className="d-flex justify-content-end mb-2">
              <Button
                size="sm"
                variant="contained"
                color="primary"
                onClick={onSaveHandler}
                autoFocus
                style={{ color: '#fff' }}
              >
                {enableLang ? `Save text (${i18n.language.toUpperCase()})` : 'Save text'}
              </Button>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                style={{ marginRight: '1rem' }}
                size="md"
                variant="outlined"
                color="primary"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                size="md"
                variant="contained"
                color="primary"
                onClick={saveAndClose}
                autoFocus
              >
                {enableLang
                  ? `Save (${i18n.language.toUpperCase()}) and Close`
                  : 'Save and Close'}
              </Button>
            </div>
          </div>
        )}

        {!edit && (
          <DialogActions>
            <Button size="md" variant="contained" color="primary" onClick={close}>
              Close
            </Button>
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ManageReview);
