import React from 'react';
import PropTypes from 'prop-types';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import GridIcon from '@material-ui/icons/Apps';
import TableChartIcon from '@material-ui/icons/TableChart';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useTheme } from '@material-ui/styles';

import Button from '../inputs/Button';

const ViewMode = ({ toggleView, activeView, isSecondPhase }) => {
  const theme = useTheme();
  const readOnly = process.env.REACT_APP_READ_ONLY;

  return (
    <div className="mx-3">
      <Button
        onClick={() => toggleView('grid')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'grid'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`,
        }}
        size="xs"
      >
        <GridIcon />
      </Button>
      {!readOnly && (
        <>
          <Button
            onClick={() => toggleView('list')}
            variant="contained"
            style={{
              backgroundColor: theme.modeColors.iconBtnBackground,
              border:
                activeView === 'list'
                  ? '2px solid #000'
                  : `1px solid ${theme.modeColors.iconBtnBorder}`,
            }}
            size="xs"
          >
            <ListIcon />
          </Button>

          <Button
            onClick={() => toggleView('table')}
            variant="contained"
            style={{
              backgroundColor: theme.modeColors.iconBtnBackground,
              border:
                activeView === 'table'
                  ? '2px solid #000'
                  : `1px solid ${theme.modeColors.iconBtnBorder}`,
            }}
            size="xs"
          >
            <TableChartIcon />
          </Button>

          {isSecondPhase && (
            <Button
              onClick={() => toggleView('videos')}
              variant="contained"
              style={{
                backgroundColor: theme.modeColors.iconBtnBackground,
                border:
                  activeView === 'videos'
                    ? '2px solid #000'
                    : `1px solid ${theme.modeColors.iconBtnBorder}`,
              }}
              size="xs"
            >
              <PlayArrowIcon />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

ViewMode.propTypes = {
  toggleView: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
};

export default ViewMode;
